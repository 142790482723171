import React from 'react';
import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        sirious.net is temporarily unavailable
        <p>
        deal with it
      </p>
      </header>
    </div>
  );
}

export default App;
